.picker-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.legends-strip{
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  /* Add a transition for the transform property to ensure smooth movement */
}

.legend-image {
  width: 150px;
  height: auto;
  margin-right: 10px;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.legend-image-vertical-slim {
  width: 100px;
  height: auto;
  margin-right: 10px;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.legend-image-square-small {
  width: 100px;
  height: auto;
  margin-right: 10px;
  transition: transform 0.3s ease; /* Smooth transition for scaling */
}

.legend-image.selected {
  transform: scale(1.1); /* Scale the selected image by 20% */
}



.spinner-container {
  padding-top: 15px;
  padding-bottom: 15px;
  width: 100%; /* Or set to a max-width if necessary */
  overflow: hidden; /* Hide the overflow to create a window effect */
  display: flex;
}


.start-button-container {
  margin-top: 15px;
  margin-bottom: 5px;
  display: flex;
  justify-content: center; /* Center the Start button */
  
}


.selected-legend {
  margin-top: 10px;
  font-size: 24px; /* Larger font size for visibility */
  color: #cccccc; /* Golden text color similar to Apex Legends UI */
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Text shadow for depth */
  padding: 15px;
  border: 3px solid #cccccc; /* Golden border */
  border-radius: 8px; /* Rounded corners */
  background: rgba(0, 0, 0, 0.8); /* Semi-transparent black background */
  display: inline-block; /* Makes the div only as wide as its content */
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.selected-legend:hover {
  transform: scale(1.1); /* Slightly enlarges the element on hover */
  cursor: pointer; /* Changes the cursor to indicate interactivity */
  color: #ffffff; /* Changes text color on hover */
}
