

h5, h6 {
    text-align: center; /* Center text */
}

.Button {
    margin-top: 20px;
}


.strategy-tile {
    display: flex;
    justify-content: center;
    align-items: center; /* This will vertically center the tile */
    padding: 20px;
    margin: 10px 0;
    border: 2px solid #4CAF50;
    background-color: #fff;
    color: #333;
    text-align: center;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: Adds shadow for better visibility */
}


.strategy-tile.highlight {
    transform: scale(1.1);
    background-color: #4CAF50;
    color: #fff;
}


.button-spinning {
    background-color: #cccccc; /* Grey background */
    color: #666; /* Darker text for better readability */
    cursor: not-allowed; /* Change cursor to indicate no interaction */
}
