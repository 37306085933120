.class-selection-container {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 16px;
  padding-bottom: 20px;
}

.inactive .MuiFormControlLabel-label {
  text-decoration: line-through; /* Strike through for inactive class labels */
  color: #a0a0a0; /* Grey color for inactive labels */
}

.MuiFormControlLabel-label {
  font-size: 1.5rem;
  color: #ffffff; /* Keep the label white */
}

.MuiCheckbox-root {
  color: #ffffff; /* Change checkbox color */
}


.active .MuiFormControlLabel-label {
  color: #000000; /* Grey color for inactive labels */
}