/* ApexLegendsPage.css or a common stylesheet */
.apex-header {
    font-size: 24px; /* Consistent font size */
    color: #000000; /* Silver color to match the selected-legend style */
    font-weight: bold;
    padding: 15px;
    
  }

  
/* ApexLegendsPage.css */
.section-divider {
    margin-bottom: 20px; /* Set bottom margin to create spacing */
    margin-top: 20px;
  }
  